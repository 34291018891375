
import { Options, Vue } from 'vue-class-component'
import Patient from '@/models/Patient'
import YesNoRadio from '@/components/YesNoRadio.vue'
import { mapActions, mapGetters } from 'vuex'

@Options({
  components: {
    YesNoRadio
  },
  methods: mapActions(['updateContraindications', 'downloadContraindications']),
  computed: mapGetters(['patient', 'contraindications'])
})
export default class Contraindications extends Vue {
  patient!: Patient
  contraindications!: Patient['contraindications']

  // Actions
  updateContraindications!: (payload: {
    id: number
    contraindications: Patient['contraindications']
  }) => Promise<void>

  downloadContraindications!: () => Promise<void>

  created (): void {
    this.downloadContraindications()
  }

  mounted (): void {
    this.$forceUpdate()
  }

  submit (): void {
    this.updateContraindications({
      id: this.patient.id,
      contraindications: this.contraindications
    })
      .then(() => {
        this.$router.push({
          name: this.$route.meta.next,
          params: { id: this.patient.id }
        })
      })
      .catch(() => {
        this.$router.push({ name: 'Logout' })
      })
  }
}
