import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col justify-center bg-white rounded-lg p-8 border-2 border-gray-300" }
const _hoisted_2 = { class: "space-y-8 divide-y divide-gray-200 sm:space-y-5" }
const _hoisted_3 = { class: "text-lg leading-6 font-medium text-gray-900" }
const _hoisted_4 = { class: "mt-1 max-w-2xl text-sm text-gray-500" }
const _hoisted_5 = { class: "flex flex-col gap-4 sm:gap-0" }
const _hoisted_6 = { class: "sm:grid sm:grid-cols-3 sm:items-start sm:border-t sm:border-gray-200" }
const _hoisted_7 = {
  for: "id",
  class: "block h-full text-sm font-medium text-gray-700 p-4 sm:py-5 flex flex-col justify-center imtes-center"
}
const _hoisted_8 = { class: "grid grid-cols-1 sm:grid-cols-2 sm:col-span-2 flex h-full" }
const _hoisted_9 = { class: "col-span-3 flex flex-col justify-center bg-gray-200" }
const _hoisted_10 = { class: "flex flex-col items-center py-4 gap-2" }
const _hoisted_11 = { class: "sm:grid sm:grid-cols-3 sm:items-start sm:border-t sm:border-gray-200" }
const _hoisted_12 = {
  for: "id",
  class: "block h-full text-sm font-medium text-gray-700 p-4 sm:py-5 flex flex-col justify-center imtes-center"
}
const _hoisted_13 = { class: "grid grid-cols-1 sm:grid-cols-2 sm:col-span-2 flex h-full" }
const _hoisted_14 = { class: "col-span-3 flex flex-col justify-center bg-gray-200" }
const _hoisted_15 = { class: "flex flex-col items-center py-4 gap-2" }
const _hoisted_16 = { class: "sm:grid sm:grid-cols-3 sm:items-start sm:border-t sm:border-gray-200" }
const _hoisted_17 = {
  for: "id",
  class: "block h-full text-sm font-medium text-gray-700 p-4 sm:py-5 flex flex-col justify-center imtes-center"
}
const _hoisted_18 = { class: "grid grid-cols-1 sm:grid-cols-2 sm:col-span-2 flex h-full" }
const _hoisted_19 = { class: "col-span-3 flex flex-col justify-center bg-gray-200" }
const _hoisted_20 = { class: "flex flex-col items-center py-4 gap-2" }
const _hoisted_21 = { class: "sm:grid sm:grid-cols-3 sm:items-start sm:border-t sm:border-gray-200" }
const _hoisted_22 = {
  for: "id",
  class: "block h-full text-sm font-medium text-gray-700 p-4 sm:py-5 flex flex-col justify-center imtes-center"
}
const _hoisted_23 = { class: "grid grid-cols-1 sm:grid-cols-2 sm:col-span-2 flex h-full" }
const _hoisted_24 = { class: "col-span-3 flex flex-col justify-center bg-gray-200" }
const _hoisted_25 = { class: "flex flex-col items-center py-4 gap-2" }
const _hoisted_26 = { class: "sm:grid sm:grid-cols-3 sm:items-start sm:border-t sm:border-gray-200" }
const _hoisted_27 = {
  for: "id",
  class: "block h-full text-sm font-medium text-gray-700 p-4 sm:py-5 flex flex-col justify-center imtes-center"
}
const _hoisted_28 = { class: "grid grid-cols-1 sm:grid-cols-2 sm:col-span-2 flex h-full" }
const _hoisted_29 = { class: "col-span-3 flex flex-col justify-center bg-gray-200" }
const _hoisted_30 = { class: "flex flex-col items-center py-4 gap-2" }
const _hoisted_31 = { class: "sm:grid sm:grid-cols-3 sm:items-start sm:border-t sm:border-gray-200" }
const _hoisted_32 = {
  for: "id",
  class: "block h-full text-sm font-medium text-gray-700 p-4 sm:py-5 flex flex-col justify-center imtes-center"
}
const _hoisted_33 = { class: "grid grid-cols-1 sm:grid-cols-2 sm:col-span-2 flex h-full" }
const _hoisted_34 = { class: "col-span-3 flex flex-col justify-center bg-gray-200" }
const _hoisted_35 = { class: "flex flex-col items-center py-4 gap-2" }
const _hoisted_36 = { class: "pt-5" }
const _hoisted_37 = { class: "flex justify-end" }
const _hoisted_38 = {
  type: "submit",
  class: "ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_yesNoRadio = _resolveComponent("yesNoRadio")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("form", {
      class: "space-y-8 divide-y divide-gray-200",
      onSubmit: _cache[7] || (_cache[7] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", null, [
          _createElementVNode("div", null, [
            _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.$t('Contraindications.Title')), 1),
            _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('Contraindications.PersonalPatientInfo')), 1)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t('Contraindications.counterindication_stimulant')), 1),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createVNode(_Transition, {
                    "enter-active-class": "animate__animated animate__fadeIn",
                    "leave-active-class": "animate__animated animate__fadeOut"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_10, [
                        (_openBlock(), _createBlock(_component_yesNoRadio, {
                          value: _ctx.contraindications.counterindication_stimulant,
                          onYesnoevent: _cache[0] || (_cache[0] = ($event: any) => (_ctx.contraindications.counterindication_stimulant = $event)),
                          key: _ctx.contraindications.counterindication_stimulant
                        }, null, 8, ["value"]))
                      ])
                    ]),
                    _: 1
                  })
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("label", _hoisted_12, _toDisplayString(_ctx.$t('Contraindications.counterindication_atx')), 1),
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("div", _hoisted_14, [
                  _createVNode(_Transition, {
                    "enter-active-class": "animate__animated animate__fadeIn",
                    "leave-active-class": "animate__animated animate__fadeOut"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_15, [
                        (_openBlock(), _createBlock(_component_yesNoRadio, {
                          value: _ctx.contraindications.counterindication_atx,
                          onYesnoevent: _cache[1] || (_cache[1] = ($event: any) => (_ctx.contraindications.counterindication_atx = $event)),
                          key: _ctx.contraindications.counterindication_atx
                        }, null, 8, ["value"]))
                      ])
                    ]),
                    _: 1
                  })
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_16, [
              _createElementVNode("label", _hoisted_17, _toDisplayString(_ctx.$t('Contraindications.counterindication_alfa')), 1),
              _createElementVNode("div", _hoisted_18, [
                _createElementVNode("div", _hoisted_19, [
                  _createVNode(_Transition, {
                    "enter-active-class": "animate__animated animate__fadeIn",
                    "leave-active-class": "animate__animated animate__fadeOut"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_20, [
                        (_openBlock(), _createBlock(_component_yesNoRadio, {
                          value: _ctx.contraindications.counterindication_alfa,
                          onYesnoevent: _cache[2] || (_cache[2] = ($event: any) => (_ctx.contraindications.counterindication_alfa = $event)),
                          key: _ctx.contraindications.counterindication_alfa
                        }, null, 8, ["value"]))
                      ])
                    ]),
                    _: 1
                  })
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_21, [
              _createElementVNode("label", _hoisted_22, _toDisplayString(_ctx.$t('Contraindications.counterindication_bup')), 1),
              _createElementVNode("div", _hoisted_23, [
                _createElementVNode("div", _hoisted_24, [
                  _createVNode(_Transition, {
                    "enter-active-class": "animate__animated animate__fadeIn",
                    "leave-active-class": "animate__animated animate__fadeOut"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_25, [
                        (_openBlock(), _createBlock(_component_yesNoRadio, {
                          value: _ctx.contraindications.counterindication_bup,
                          onYesnoevent: _cache[3] || (_cache[3] = ($event: any) => (_ctx.contraindications.counterindication_bup = $event)),
                          key: _ctx.contraindications.counterindication_bup
                        }, null, 8, ["value"]))
                      ])
                    ]),
                    _: 1
                  })
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_26, [
              _createElementVNode("label", _hoisted_27, _toDisplayString(_ctx.$t('Contraindications.counterindication_mod')), 1),
              _createElementVNode("div", _hoisted_28, [
                _createElementVNode("div", _hoisted_29, [
                  _createVNode(_Transition, {
                    "enter-active-class": "animate__animated animate__fadeIn",
                    "leave-active-class": "animate__animated animate__fadeOut"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_30, [
                        (_openBlock(), _createBlock(_component_yesNoRadio, {
                          value: _ctx.contraindications.counterindication_mod,
                          onYesnoevent: _cache[4] || (_cache[4] = ($event: any) => (_ctx.contraindications.counterindication_mod = $event)),
                          key: _ctx.contraindications.counterindication_mod
                        }, null, 8, ["value"]))
                      ])
                    ]),
                    _: 1
                  })
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_31, [
              _createElementVNode("label", _hoisted_32, _toDisplayString(_ctx.$t('Contraindications.counterindication_tca')), 1),
              _createElementVNode("div", _hoisted_33, [
                _createElementVNode("div", _hoisted_34, [
                  _createVNode(_Transition, {
                    "enter-active-class": "animate__animated animate__fadeIn",
                    "leave-active-class": "animate__animated animate__fadeOut"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_35, [
                        (_openBlock(), _createBlock(_component_yesNoRadio, {
                          value: _ctx.contraindications.counterindication_tca,
                          onYesnoevent: _cache[5] || (_cache[5] = ($event: any) => (_ctx.contraindications.counterindication_tca = $event)),
                          key: _ctx.contraindications.counterindication_tca
                        }, null, 8, ["value"]))
                      ])
                    ]),
                    _: 1
                  })
                ])
              ])
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_36, [
        _createElementVNode("div", _hoisted_37, [
          _createElementVNode("button", {
            onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$router.back())),
            type: "button",
            class: "bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          }, _toDisplayString(_ctx.$t('Contraindications.Back')), 1),
          _createElementVNode("button", _hoisted_38, _toDisplayString(_ctx.$t('Contraindications.Save')), 1)
        ])
      ])
    ], 32)
  ]))
}